







import PaymentTerms from "@/components/common/PaymentTerms.vue";
import AppDialog from "@/components/Navigation/AppDialog.vue";
import Vue from "vue";
import Component from "vue-class-component";

@Component({
  name: "payment-terms-page",
  components: {
    AppDialog,
    PaymentTerms
  }
})
export default class PaymentTermsPage extends Vue {}
